import React, { useEffect, useMemo } from 'react'
import { AiChat, useAiChatApi , useAsStreamAdapter } from '@nlux/react';
import '@nlux/themes/nova.css';
import "./Assistant.style.css";
import { Box, Button, IconButton, Typography } from '@mui/material';
import { CloseRounded, OpenInFull, CloseFullscreen, Mic, RecordVoiceOver, VoiceOverOff } from '@mui/icons-material';
import {  sendAssistantMessageStream } from './utils';
import { AIAvatar, UserAvatar } from './Avatars';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { utilityActions } from '~/store/context/utilitySlice';
import { useSpeechToText } from './speechToText';
import { useTextToSpeak } from './textToSpeech';
import {Icons} from "../../components/GraphicalResources";

export const CHATBOT_WIDTH = '25vw';
const BOT_NAME = 'Virtual Engineer';

const styles: Record<string, React.CSSProperties> = {
  button: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: '1rem',
    zIndex: 1000,
  },
  chat : {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: CHATBOT_WIDTH,
    transition: 'height 0.5s',
    boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
    borderRadius: '10px 10px 0 0',
    background: 'white',
    zIndex: 1000,
  },

} as const

type Props = {
}


const Assistant = (props: Props) => {
  const dispatch = useAppDispatch();
  const nluxAdapter = useAsStreamAdapter(sendAssistantMessageStream);

  const nluxApi = useAiChatApi();

  const open = useAppSelector((state) => state.utility.chatBot.open);
  const height = useAppSelector((state) => state.utility.chatBot.height);
  const maximized = useAppSelector((state) => state.utility.chatBot.maximize);
  const waitingForResponse = useAppSelector((state) => state.utility.chatBot.waitingForResponse);

  const setOpen = (open: boolean) => {
    dispatch({ type: 'utility/setChatBotOpen', payload: open })
  }

  const { text , startListening , stopListening , isListening} = useSpeechToText();

  useEffect(() => {
    const handleVoiceText = (text: string) => {
      nluxApi.composer.send(text);
    }

    if (text) {
      handleVoiceText(text);
    }
  }, [nluxApi.composer, text]);

  const { speak, enabled, toggleTranscription, stopSpeaking } = useTextToSpeak();

  // Get the conversation from the session storage if it exists IF the chatbot is open
  const initConversation = useMemo(() => {
    if (!open) {
      return undefined;
    }
    const initConversation = sessionStorage.getItem('chatHistory') ? JSON.parse(sessionStorage.getItem('chatHistory')!) : undefined;
    return initConversation;
  }, [open]);



  if (!open) {
    return (
      <Button 
          aria-label="open"
          content={BOT_NAME} 
          onClick={() => {setOpen(true)}} 
          color='inherit'
          className='chatbot-fab' >
        {Icons.VirtualEngineer}
      </Button>
    )
  }

  return (
    <div style={{
      ...styles.chat,
      height: height,
    }}>
      <Box p={2} display={'flex'} className='header' justifyContent={'space-between'} alignItems={'center'} >
        <Typography variant="subtitle1" fontSize={'1rem'}>
          {Icons.VirtualEngineer}&nbsp;&nbsp;<b>{BOT_NAME}</b>
        </Typography>
        <Box>

          <IconButton aria-label="record" disabled={waitingForResponse}
          className={isListening ? 'animate-recording' : ''} size='small' style={{ fontSize: '1.1rem'
          }} onClick={() => {
            if (isListening) {
              stopListening();
            } else {
              stopSpeaking();
              startListening();
          }}}
          >
            <Mic />
          </IconButton>

          <IconButton aria-label="text-to-speech" onClick={() => {
            toggleTranscription();
            if (enabled) {
              stopSpeaking();
            }

          }}>
            { enabled ? <RecordVoiceOver /> : <VoiceOverOff />}
          </IconButton>

          <IconButton aria-label="maximize" onClick={() => {
            dispatch(utilityActions.maximizeChatBot())
          }} >
            {!maximized ? <OpenInFull /> : <CloseFullscreen />}
          </IconButton>
          <IconButton aria-label="close" onClick={() => {
            setOpen(false)
          }}>
            <CloseRounded />
          </IconButton>
        </Box>

      </Box>
      <AiChat adapter={nluxAdapter} api={nluxApi}
        composerOptions={{
          placeholder: "Ask me anything...",
          autoFocus : true,
        }}
        className='ai-chat'
        events={{
          messageSent: (evt) => {
            dispatch(utilityActions.setChatBotWaitingForResponse(true));
          },
          messageReceived: (evt) => {
            const isArr = Array.isArray(evt.message);
            const text = isArr ? evt.message as unknown as string[] : [evt.message]
            speak(text.join(''));
            dispatch(utilityActions.setChatBotWaitingForResponse(false));
          }
        }}
        conversationOptions={{
          autoScroll: true,
          conversationStarters: [
            {
              prompt: "Show me the list of radios available",
              label: "Get List of Radios",
            },
            {
              prompt: "Show me the list of edges available",
              label: "Get List of Edges",
            }
          ],
        }}
        initialConversation={initConversation}
        displayOptions={{
          colorScheme: 'light',
          height: 'calc(100% - 64px)',
        }}
        personaOptions={{
          assistant: {
            avatar: <AIAvatar />,
            name: BOT_NAME,
            tagline: 'Highway 9 Virtual Engineer',
          },
          user: {
            avatar: <UserAvatar />,
            name: 'User',
          }
        }}
      >
      </AiChat>
    </div>

  )
}

export default Assistant