import { UserIcon } from "@h9/ui-lib"
import { authenticationService } from "~/services"
import {Icons} from "../../components/GraphicalResources"


const user = authenticationService.currentUserValue?.userName

 function AIAvatar() {
    return (
        <span className="bot-avatar" title="Virtual Engineer">{Icons.VirtualEngineer}</span>
    )
}

 function UserAvatar() {
    return (
        <span className="user-avatar">
            <UserIcon title={user} size={24} color="#616161" />
        </span>
    )
}

export { AIAvatar, UserAvatar }
