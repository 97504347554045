// @ Copyright 2024 - present Highway9 Networks
type SocketEvents = {
    systemConecting?: () => void
    systemError?: (error: string) => void
    systemMessage?: (message: string) => void
  }

type SocketOptions = {
    token?: string,
    type? : "text" | "json" | "binary" 
}
  
  let timeOut = 5;
  
  class Socket {
    socket: null | WebSocket
    events: SocketEvents
    type : "text" | "json" | "binary"
    constructor(events?: SocketEvents) {
      this.socket = null
      this.type = "text"
      this.events = {
        systemConecting: () => { console.log('Connecting to socket') },
        systemError: (error: string) => { console.error(error) },
        systemMessage: (message: string) => { console.log(message) },
        ...events
      }
    }
  
    connect(url: string , { token, type  } : SocketOptions ) {
      try {
        if (!this.socket) {
          this.socket = new WebSocket(url)
          if(type) this.type = type;

          this.events.systemConecting?.();
          this.socket.onopen = () => {
            console.log('Socket connection established')
            if (token) this.send("<<create_session>> " + token)
            timeOut = 5
          }
  
          this.socket.onerror = (error) => {
            this.events.systemError?.(JSON.stringify(error))
          }
  
  
          this.socket.onclose = () => {
            this.events.systemMessage?.(`Socket closed. Reconnecting in ${timeOut} seconds`)
            this.socket = null
            setTimeout(() => {
              this.connect(url, { token, type })
              timeOut = Math.min(timeOut * 2, 60)
            }, timeOut * 1000)
          }
        }
      } catch (error) {
        console.log(error)
        console.log(`'Error connecting to socket. Reconnecting in ${timeOut} seconds`)
        this.socket = null
        setTimeout(() => {
          this.connect(url, { token, type })
          timeOut = Math.min(timeOut * 2, 60)
        }, timeOut * 1000)
        throw error
      }
    }
  
    disconnect() {
      if (this.socket) {
        this.socket.close()
        this.socket = null
      }
    }
  
    send(message: Record<string, string | number> | string) {
    let data : string | Blob;
      switch (this.type) {
        case "text":
          data = message as string;
          break;
        case "json":
          data = JSON.stringify(message);
          break;
        case "binary":
          data = new Blob([JSON.stringify(message)], { type: 'application/json' });
          break;
        default:
          data = message as string;
          break;
      }
      try {
        if (this.socket) {
          this.socket.send(data)
        }
      } catch (error) {
        console.log(error)
        this.events.systemError?.(JSON.stringify(error))
        throw 'Error sending message to socket';
        
      }
    }
  
    on<T extends keyof WebSocketEventMap>(event: T, callback: (event: WebSocketEventMap[T]) => void) {
      if (this.socket) {
        this.socket.addEventListener(event, callback)
      }
    }
  }
  
  export default Socket