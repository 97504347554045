/***************************************************
 *  Copyright 2024-2025 Highway9 Networks Inc.     *
 ***************************************************/
import { ChatAdapterExtras, StreamSend } from "@nlux/react";
import { authenticationService } from "~/services";
import Socket from "./websocket";

function getWebSocket() {
  const env = window.location.protocol === 'https:' ? `wss://` :  `ws://`
  const url = `${env}${window.location.host}/ws/chat/`;
  const connection =  new Socket();
  connection.connect(url , {
    token: authenticationService.currentUserValue?.token,
  });

  return connection;
}


const ws = getWebSocket();



export type BatchSend<AiMsg> = (
  message: string,
  extras: ChatAdapterExtras<AiMsg>,
) => Promise<AiMsg>;

export const sendAssistantMessage : BatchSend<string> = async (message, extras) => {

  console.log(extras)
  ws.send(message);
  extras.conversationHistory?.push({
    role: 'user',
    message,
  });

  console.log("👤:", message)

  const response = await new Promise<string>((resolve) => {
    ws.on('message', (event) => {
      resolve(event.data)
    })
  });

  extras.conversationHistory?.push({
    role: 'assistant',
    message: response,
  });

  sessionStorage.setItem('chatHistory', JSON.stringify(extras.conversationHistory))

  console.log("🤖:", response)

  return response;
}


export const sendAssistantMessageStream : StreamSend = (message, observer) => {
  const conversationHistory = JSON.parse(sessionStorage.getItem('chatHistory') || '[]');

  ws.send(message);
  conversationHistory?.push({
    role: 'user',
    message,
  });

  console.log("👤:", message)

  let responseMessage = "";
  let agentName = "Virtual Engineer";
  ws.on("message",(event) => {
    const response = event.data;

    // regex to extract string between <<< and >>>
    const regex = /<<<(.*?)>>>/;
    const match = regex.exec(response);

    if (match && match[1] !== "END") {
      agentName = match[1];
    } else if (match && match[1] === "END") {
      observer.complete();
      console.log("🤖 ", agentName , ": ", responseMessage);
      conversationHistory?.push({
        role: 'assistant',
        message: responseMessage.toString(),
      });
      sessionStorage.setItem('chatHistory', JSON.stringify(conversationHistory))

      responseMessage = "";
    } else {
      observer.next(response);
      responseMessage += response;
    }
  });
};