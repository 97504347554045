/**
 * Copyright Highway9 Networks Inc.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "..";
import { Types } from "../../constants/types";
import moment from "moment-timezone";
import { metricHelper } from "~/helpers/metricHelper";
import { updateURL } from '~/helpers/history';
import { observeStoreChange } from "../utils";
import { settingsAssistantEnabled } from "./settingsSlice";

type mousePosition = {
  mouseX: number;
  mouseY: number;
} | null;

type initState = {
  menu: {
    anchor: mousePosition;
    type: string;
  };
  selectedID: string;
  selectedType: string;

  //
  time: {
    startTime: number;
    endTime: number;
    interval: string;
    diff: number;
    aggregationPeriod: string;
    timeZone: string;
  };

  compare: {
    enable: boolean;
    startTime: number;
    endTime: number;
  }

  chatBot: {
    open: boolean;
    height?: string;
    maximize: boolean;
    waitingForResponse: boolean;
  }, 
};

const time = {
  endTime: moment().unix(),
  startTime: moment().subtract(1, 'week').unix(),
  interval: "1week",
  compareDistance: 60 * 60 * 24 * 3 // 3 days in seconds
};

const initialState: initState = {
  menu: {
    anchor: null,
    type: "",
  },
  selectedID: Types.all,
  selectedType: Types.all,

  time: {
    startTime: time.startTime,
    endTime: time.endTime,
    interval: time.interval,
    diff: time.endTime - time.startTime,
    aggregationPeriod: "1 Day",
    timeZone: moment.tz.guess(),
  },
  compare: {
    enable: false,
    startTime: time.startTime - time.compareDistance,
    endTime: time.endTime - time.compareDistance,
  },

  chatBot: {
    open: false,
    height: 'calc(100vh - 64px)',
    maximize: true,
    waitingForResponse: false,
  }
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<{ anchor: mousePosition; type: string }>) => {
      state.menu = action.payload;
    },
    setSelectedID: (state, action: PayloadAction<string>) => {
      state.selectedID = action.payload;
      const type = action.payload.split("-")[0];
      state.selectedType = type;
    },

    setTimeInterval: (state, action: PayloadAction<{
      interval: string; rounding?: number; shouldUpdateTime?: boolean;
    }>) => {
      const { rounding, interval, shouldUpdateTime } = action.payload;
      // Check if interval is in the query params
      const intervalParam = new URLSearchParams(window.location.search).get('interval');
      const startTimeParam = new URLSearchParams(window.location.search).get('startTime');
      const endTimeParam = new URLSearchParams(window.location.search).get('endTime');

      // check if no change in interval
      if (state.time.interval === interval && intervalParam === interval) return;

      state.time.interval = interval;
      updateURL({
        interval: state.time.interval, startTime: state.time.startTime,
        endTime: state.time.endTime
      });

      // check if custom interval is set then return
      if (interval === "custom") {
        return;
      }

      if (shouldUpdateTime || !startTimeParam || !endTimeParam) {
        const { startTime, endTime } = metricHelper.getIntervalTime(interval, rounding);
        state.time.startTime = startTime;
        state.time.endTime = endTime;
        state.time.diff = endTime - startTime;
        updateURL({
          startTime: state.time.startTime,
          endTime: state.time.endTime,
        });
      }
    },
    seekTimeInterval: (
      state,
      action: {
        payload: {
          direction: -1 | 1; // -1: backward, 1: forward
          distance: number; // in seconds
        };
      }
    ) => {
      const { direction, distance } = action.payload;
      const { startTime, endTime } = state.time;

      const newStartTime = startTime + direction * distance;
      const newEndTime = endTime + direction * distance;

      if (newEndTime >= moment().unix() && direction === 1) {
        refreshTime(state);
        return;
      }

      state.time.startTime = newStartTime;
      state.time.endTime = newEndTime;
      state.time.diff = newEndTime - newStartTime;

      updateURL({
        startTime: state.time.startTime,
        endTime: state.time.endTime,
        interval: state.time.interval,
      });
    },

    refreshTimeInterval: (state) => refreshTime(state),

    setTimeZone: (state, action: PayloadAction<string>) => {
      state.time.timeZone = action.payload;
    },
    setStartTime: (state, action: PayloadAction<number>) => {
      // check if the data is not same as the current data
      if (state.time.startTime === action.payload) return;
      state.time.startTime = action.payload;
      state.time.diff = state.time.endTime - action.payload;
      updateURL({
        startTime: state.time.startTime,
      });
    },
    setEndTime: (state, action: PayloadAction<number>) => {
      // check if the data is not same as the current data
      if (state.time.endTime === action.payload) return;
      state.time.endTime = action.payload;
      state.time.diff = action.payload - state.time.startTime;
      updateURL({
        endTime: state.time.endTime,
      });
    },
    setAggregationPeriod: (state, action: PayloadAction<string>) => {
      state.time.aggregationPeriod = action.payload;
      updateURL({
        aggregationPeriod: state.time.aggregationPeriod,
      });
    },

    toogleCompare: (state) => {
      state.compare.enable = !state.compare.enable;

      // if compare is enabled then set the compare time interval based on the current time and interval
      if (state.compare.enable) {
        const compareDistance = state.time.diff * 2;
        state.compare.endTime = state.time.startTime - compareDistance;
        state.compare.startTime = state.compare.endTime - state.time.diff;
      }
    },

    resetCompare: (state) => {
      // if compare is enabled then set the compare time interval based on the current time and interval
      if (state.compare.enable) {
        const compareDistance = state.time.diff * 2;
        state.compare.endTime = state.time.startTime - compareDistance;
        state.compare.startTime = state.compare.endTime - state.time.diff;
      }
    },

    setCompareStartTime: (state, action: PayloadAction<number>) => {
      if (!state.compare.enable) {
        console.warn("Compare is not enabled");
        return;
      }
      state.compare.startTime = action.payload;
    },

    setCompareEndTime: (state, action: PayloadAction<number>) => {
      if (!state.compare.enable) {
        console.warn("Compare is not enabled");
        return;
      }
      state.compare.endTime = action.payload;
    },

    setCompareTimeInterval: (state, action: PayloadAction<{
      interval: string; rounding?: number;
    }>) => {
      if (!state.compare.enable) {
        console.warn("Compare is not enabled");
        return;
      }
      const { rounding, interval } = action.payload;
      if (interval === "custom") return;
      const { startTime, endTime } = metricHelper.getIntervalTime(interval, rounding);
      state.compare.startTime = startTime;
      state.compare.endTime = endTime;
    },

    seekCompareTimeInterval: (
      state,
      action: {
        payload: {
          direction: -1 | 1; // -1: backward, 1: forward
          distance: number; // in seconds
        };
      }
    ) => {
      if (!state.compare.enable) {
        console.warn("Compare is not enabled");
        return;
      }
      const { direction, distance } = action.payload;
      const { startTime, endTime } = state.compare;

      const newStartTime = startTime + direction * distance;
      const newEndTime = endTime + direction * distance;

      if (newEndTime >= moment().unix() && direction === 1) {
        refreshTime(state);
        return;
      }

      state.compare.startTime = newStartTime;
      state.compare.endTime = newEndTime;
    },

    setChatBotOpen: (state, action: PayloadAction<boolean>) => {
      state.chatBot.open = action.payload
      // if (action.payload === false) {
      //   state.chatBot.height = '50vh';
      //   state.chatBot.maximize = false;
      // }
    },

    setChatBotHeight: (state, action: PayloadAction<string>) => {
      state.chatBot.height = action.payload;
    },

    maximizeChatBot: (state) => {
      state.chatBot.maximize = !state.chatBot.maximize;
      if (state.chatBot.maximize) {
        state.chatBot.height = 'calc(100vh - 64px)';
      } else {
        state.chatBot.height = '50vh';
      }
    },

    setChatBotWaitingForResponse: (state, action: PayloadAction<boolean>) => {
      state.chatBot.waitingForResponse = action.payload;
    }
  },
});

export const utilityActions = utilitySlice.actions;
export const { setMenu, setSelectedID } = utilityActions;
export const selectMenu = (state: RootState) => state.utility.menu;
export const SelectedID = (state: RootState) => state.utility.selectedID;
export const selectedType = (state: RootState) => state.utility.selectedType;

export const selectTimeZone = (state: RootState) => state.utility.time.timeZone;
export const selectTimeInterval = (state: RootState) => state.utility.time.interval;
export const selectStartTime = (state: RootState) => state.utility.time.startTime;
export const selectEndTime = (state: RootState) => state.utility.time.endTime;
export const selectTimeDiff = (state: RootState) => state.utility.time.diff;
export const selectAggregationPeriod = (state: RootState) => state.utility.time.aggregationPeriod
export const selectCompareEnable = (state: RootState) => state.utility.compare.enable;
export const selectCompareStartTime = (state: RootState) => state.utility.compare.startTime;
export const selectCompareEndTime = (state: RootState) => state.utility.compare.endTime;

export const selectChatBotOpen = (state: RootState) => state.utility.chatBot.open;

export default utilitySlice.reducer;

function refreshTime(state: initState) {
  if (state.time.interval === "custom") {
    const timeDiff = state.time.endTime - state.time.startTime;
    state.time.startTime = moment().unix() - timeDiff;
    state.time.endTime = moment().unix();
    return;
  }
  const { startTime, endTime } = metricHelper.getIntervalTime(state.time.interval);
  state.time.startTime = startTime;
  state.time.endTime = endTime;
  state.time.diff = endTime - startTime;
}


setTimeout(() => {
  observeStoreChange(settingsAssistantEnabled, (state) => {
    if (!state) {
      store.dispatch(utilityActions.setChatBotOpen(false));
    } 
  });
});