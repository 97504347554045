import { useState } from "react";

class TextToSpeak {
    private synth = window.speechSynthesis
    private voices = this.synth.getVoices();
    lang = 'en-US';
  
    speak(text: string) {
    //    check if the browser supports speech synthesis
        if (!this.synth) {
            console.warn('Your browser does not support speech synthesis');
            return;
        }
 
    // check if a speech is already in progress
        if (this.synth.speaking) {
            console.error('speechSynthesis.speaking');
            this.synth.cancel();
        }
        // Create a new instance of SpeechSynthesisUtterance
      const utterance = new SpeechSynthesisUtterance(text);
      const voiceList = this.synth.getVoices().filter((voice) => voice.lang === this.lang && voice.localService);
      utterance.voice = voiceList.find((voice) => voice.name.includes("Mark")) ?? voiceList[0];
      console.log(utterance.voice);
      this.synth.speak(utterance);
    }
  
    stop() {
      this.synth.cancel();
    }
  
    setVoice(lang: string) {
      this.voices = this.synth.getVoices().filter((voice) => voice.lang === lang);
    }
  
    listofLang() {
      const list = this.synth.getVoices().map((voice) => voice.lang);
      return Array.from(new Set(list));
    } 
  }
  
  const textToSpeak = new TextToSpeak();
  
  export const useTextToSpeak = () => {
  
    const [enabled, setEnabled] = useState(false);
  
    const toggleTranscription = () => {
      setEnabled(!enabled);
    }
  
    const speak = (text: string) => {
      if (!enabled) return;
      textToSpeak.speak(text);
    }
  
    const setVoice = (lang: string) => {
      textToSpeak.setVoice(lang);
    }
  
    const listofLang = () => {
      return textToSpeak.listofLang();
    }
  
    const stopSpeaking = () => { textToSpeak.stop() }
  
    return { speak , setVoice , listofLang,  stopSpeaking, enabled, toggleTranscription };
  }
  